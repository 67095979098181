import * as Sentry from "@sentry/react";
//

const isProduction = process.env.NODE_ENV === 'production'
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
/*
ALL 9
DEBUG 8
EXTRA 2? 5?
MIN 1
NONE 0
*/
//const loggingLevel = process.env.REACT_APP_LOGGING_LEVEL;

export const mpoSentry = {

    addBreadcrumb: function(cat, msg, lvl) {
        if (isProduction && !isCustomApp) {
            Sentry.addBreadcrumb({
                category: cat,
                message: msg,
                level: lvl
            });
        }
    },

    captureMessage: function(msg, lvl) {
        if (isProduction && !isCustomApp) {
            Sentry.captureMessage(msg, lvl);
        }
    },

    captureException: function(err) {
        // always log exceptions
        if (isProduction) {
            Sentry.captureException(err);
        }
    }

}