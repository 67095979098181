import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';

import { mpoSentry } from '../../lib/Sentry';
//import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";
import * as actions from "../../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {updateObject} from "../../shared/utility";
import {mpoFulfilment} from "../../lib/Fulfilment";
import StandingOrderProductItem from "../../components/account/StandingOrderProductItem";
import DeliveryAddress from "../../components/account/DeliveryAddress";
import DeliverySlot from "../../components/account/DeliverySlot";
import Modifiers from "../Menu/Modifiers";

class AccountStandingOrder extends Component {

    constructor(props) {
        super(props);
        //console.log('Standing Order props', props);

        const orderSlug = this.props.match !== undefined && this.props.match.params.hasOwnProperty('slug') ? this.props.match.params.slug : 0;
        //console.log(orderSlug);

        this.state = {
            isLoading: true,
            isSaving: false,
            mode: orderSlug > 0 ? 'edit' : 'new',
            stores: [],
            merchant: null,
            delivery: {
                delivery_address_id: 0,
                delivery_address: "",
                delivery_address1: "",
                delivery_address2: "",
                delivery_city: "",
                delivery_state: "",
                delivery_postcode: "",
                delivery_country: "",
                delivery_geo: {},
                delivery_latlng: {},
                delivery_validated: false,
                delivery_postcode_id: 0,
                delivery_slot_id: 0,
                delivery_stop_id: 0,
                result: {
                    matched: false
                }
            },
            category_id: 0,
            standing_order_id: 0,
            standing_order: {
                id: 0,
                store_id: 0,
                menu_id: 0,
                customer_id: this.props.user.customer.id,
                customer_billing_id: 0,
                fulfilment_type: null,
                fulfilment_time: null,
                contactless: 0,
                payment_method: null,
                pickup_location_id: 0,
                delivery_address_id: 0,
                delivery_slot_id: 0,
                delivery_stop_id: 0,
                student_name: null,
                store_class_id: 0,
                note: null,
                status: 0,
                items: []
            }
        }

        this.handleChangeProductQty = this.handleChangeProductQty.bind(this);
        this.handleChangeDeliveryAddress = this.handleChangeDeliveryAddress.bind(this);
        this.handleChangeDeliverySlot = this.handleChangeDeliverySlot.bind(this);
        this.loadModifiersPostDataHandler = this.loadModifiersPostDataHandler.bind(this);
        this.updateStateWithModifiers = this.updateStateWithModifiers.bind(this);
        this.modifiersModal = React.createRef();
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','StandingOrder','info');
        this.getAccount();
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "AUTH") {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props, this.state.standing_order_id);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                } else if (response.data.ResponseCode === "SUCCESS") {
                    if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                        this.props.updateStateWithCustomer({id: 0, status: 0}, this.props, this.state.standing_order_id);
                        mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                        //mpoSentry.captureMessage('Session expired', 'warning');
                    } else {
                        this.props.updateStateWithCustomer(response.data.Response.customer, null);
                        this.setState({
                            modules: {
                                standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                                rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                                topup: response.data.Response.account.is_topup_enabled === 1,
                                facebook: response.data.Response.customer.is_facebook_connected,
                                apple: response.data.Response.customer.is_apple_connected,
                                google: response.data.Response.customer.is_google_connected
                            }
                        });

                        let orderSlug = this.props.match !== undefined ? this.props.match.params.slug : 0;
                        if (orderSlug && parseInt(orderSlug, 10) > 0 && orderSlug !== this.state.standing_order.id) {
                            // fetch specific order
                            this.loadStandingOrder(orderSlug);
                        } else {
                            // fetch values for new order
                            this.loadStandingOrder(0);
                        }
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    loadStandingOrder = (standingOrderId) => {

        const data = {
            RequestAction: 'StandingOrders',
            sub_action: 'load',
            store_id: this.isValidStoreSelected() ? this.state.standing_order.store_id : 0,
            standing_order_id: standingOrderId
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response, this.props);
                if (response.data.ResponseCode === "AUTH") {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props, this.state.standing_order_id);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                } else if (response.data.ResponseCode === "SUCCESS") {
                    if (response.data.Response.standing_order !== null && response.data.Response.standing_order.hasOwnProperty('id')) {
                        let standing_order = response.data.Response.standing_order;
                        const delivery = updateObject(this.state.delivery, {
                            delivery_address_id: standing_order.delivery_address_id,
                            delivery_slot_id: standing_order.delivery_slot_id,
                            delivery_stop_id: standing_order.delivery_stop_id
                        });
                        this.setState({
                            isLoading: false,
                            mode: standing_order.id > 0 ? 'edit' : 'new',
                            stores: response.data.Response.stores,
                            merchant: response.data.Response.merchant,
                            standing_order_id: standing_order.id,
                            standing_order: standing_order,
                            delivery: delivery
                        });
                    } else {
                        const standing_order = updateObject(this.state.standing_order, {
                            id: 0,
                        });
                        this.setState({
                            isLoading: false,
                            mode: 'new',
                            stores: response.data.Response.stores,
                            merchant: response.data.Response.merchant,
                            standing_order_id: 0,
                            standing_order: standing_order
                        });
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger', duration: 10000});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    this.setState({
                        isLoading: false,
                    });

                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    reloadStandingOrder = () => {
        this.loadStandingOrder(this.state.standing_order_id);
    }

    saveStandingOrder = (statusOnly = false) => {

        const data = {
            RequestAction: 'StandingOrders',
            sub_action: statusOnly ? 'status' : 'save',
            store_id: this.isValidStoreSelected() ? this.state.standing_order.store_id : 0,
            standing_order_id: this.state.standing_order_id,
            standing_order_data: this.state.standing_order,
            delivery_address: this.state.delivery
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response, this.props);
                if (response.data.ResponseCode === "AUTH") {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again', color: 'danger'});
                } else if (response.data.ResponseCode === "SUCCESS") {
                    this.props.updateStateWithCustomer(response.data.Response.customer, null);
                    if (response.data.Response.standing_order !== null && response.data.Response.standing_order.hasOwnProperty('id')) {
                        let standing_order = response.data.Response.standing_order;
                        const delivery = updateObject(this.state.delivery, {
                            delivery_address_id: standing_order.delivery_address_id,
                            delivery_slot_id: standing_order.delivery_slot_id,
                            delivery_stop_id: standing_order.delivery_stop_id
                        });
                        this.setState({
                            isSaving: false,
                            mode: standing_order.id > 0 ? 'edit' : 'new',
                            stores: response.data.Response.stores,
                            merchant: response.data.Response.merchant,
                            standing_order_id: standing_order.id,
                            standing_order: standing_order,
                            delivery: delivery
                        });
                        const statusText = parseInt(standing_order.status,10) === 1 ? 'Active' : 'Paused';
                        if (statusOnly) {
                            mobiscroll.toast({message: "Standing Order "+statusText, color: 'info'});
                        } else {
                            mobiscroll.toast({message: "Standing Order Saved ("+statusText+")", color: 'success'});
                        }
                    } else {
                        mobiscroll.toast({message: 'Something went wrong, please try again', color: 'danger'});
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    saveStandingOrderStatus = () => {
        this.saveStandingOrder(true);
    }

    getStoresJsx = () => {
        let storesJsx = null;
        if (this.state.stores !== null && this.state.stores.length > 0) {
            storesJsx = <label>
                Store
                <mobiscroll.Select
                    select="single"
                    value={this.state.standing_order.store_id}
                    placeholder="Choose Store"
                    onSet={this.setStoreId}
                    disabled={this.state.mode !== 'new'}
                >
                    <option key={0} value={0}>Select Store</option>
                    {this.state.stores.map((store, idx) => <option key={store.id}
                                                                   value={store.id}>{store.name} {store.suburb}</option>)}
                </mobiscroll.Select>
            </label>
        } else {
            storesJsx = <mobiscroll.Note color="danger">
                No stores enabled or insufficent permissions.
            </mobiscroll.Note>
        }
        return storesJsx;
    }

    setStoreId = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({
            standing_order: updateObject(this.state.standing_order, {
                store_id: parseInt(inst.getVal(), 10)
            })
        }, this.reloadStandingOrder);
    }

    isValidStoreSelected = () => {
        return this.state.standing_order !== null && this.state.standing_order.hasOwnProperty('store_id') && parseInt(this.state.standing_order.store_id, 10) > 0
            //&& this.state.merchant !== null && parseInt(this.state.merchant.id, 10) === parseInt(this.state.standing_order.store_id, 10);
    }

    getMerchantLocale = () => {
        return this.state.merchant !== null && this.state.merchant.hasOwnProperty('locale') ? this.state.merchant.locale : null;
    }

    getStoreStandingOrderSettings = () => {
        if (this.isValidStoreSelected()) {
            return this.state.stores.find(store => parseInt(store.id,10) === parseInt(this.state.standing_order.store_id, 10));
        }
        return null;
    }

    getSelectedFulfilmentType = () => {
        if (this.isSelectedFulfilmentTypePickup()) {
            return mpoFulfilment.selectFulfilmentType(this.state.merchant.fulfilment, mpoFulfilment.fulfilmentTypeCodePickup);
        } else if (this.isSelectedFulfilmentTypeDinein()) {
            return mpoFulfilment.selectFulfilmentType(this.state.merchant.fulfilment, mpoFulfilment.fulfilmentTypeCodeDinein);
        } else if (this.isSelectedFulfilmentTypeDelivery()) {
            return mpoFulfilment.selectFulfilmentType(this.state.merchant.fulfilment, mpoFulfilment.fulfilmentTypeCodeDelivery);
        }
        return false;
    }

    isSelectedFulfilmentTypePickup = () => {
        if (this.isValidFulfilmentTypeSelected()) {
            return this.state.standing_order.fulfilment_type === mpoFulfilment.fulfilmentTypePickup;
        }
        return false;
    }

    isSelectedFulfilmentTypeDinein = () => {
        if (this.isValidFulfilmentTypeSelected()) {
            return this.state.standing_order.fulfilment_type === mpoFulfilment.fulfilmentTypeDinein;
        }
        return false;
    }

    isSelectedFulfilmentTypeDelivery = () => {
        if (this.isValidFulfilmentTypeSelected()) {
            return this.state.standing_order.fulfilment_type === mpoFulfilment.fulfilmentTypeDelivery;
        }
        return false;
    }

    getInfoJsx = () => {
        if (this.isAllValidFulfilmentOptionSelected()) {
            const info = this.getStoreStandingOrderSettings();
            if (info !== null) {
                //console.log(info);
                let cutOffText = null;
                let discountText = null;

                if (this.isSelectedFulfilmentTypePickup()) {
                    cutOffText = "Cut off for Pickup Standing Order changes is " + info.cut_off_time_pickup_12h;
                    if (parseInt(info.cut_off_days_pickup,10) > 1) {
                        cutOffText += " " + info.cut_off_days_pickup + " days before fulfillment.";
                    } else if (parseInt(info.cut_off_days_pickup,10) > 0) {
                        cutOffText += " for next day fulfillment.";
                    } else {
                        cutOffText += " daily.";
                    }
                    if (parseFloat(info.discount_percent_pickup) > 0) {
                        discountText = " Pickup Standing Orders receive a " + info.discount_percent_pickup + "% discount off the list price.";
                    }
                } else if (this.isSelectedFulfilmentTypeDinein()) {
                    cutOffText = "Cut off for Dine-in Standing Order changes is " + info.cut_off_time_dinein_12h;
                    if (parseInt(info.cut_off_days_dinein,10) > 1) {
                        cutOffText += " " + info.cut_off_days_dinein + " days before fulfillment.";
                    } else if (parseInt(info.cut_off_days_dinein,10) > 0) {
                        cutOffText += " for next day fulfillment.";
                    } else {
                        cutOffText += " daily.";
                    }
                    if (parseFloat(info.discount_percent_dinein) > 0) {
                        discountText = " Dine-in Standing Orders receive a " + info.discount_percent_dinein + "% discount off the list price.";
                    }
                } else if (this.isSelectedFulfilmentTypeDelivery()) {
                    cutOffText = "Cut off for Delivery Standing Order changes is " + info.cut_off_time_deliver_12h;
                    if (parseInt(info.cut_off_days_deliver,10) > 1) {
                        cutOffText += " " + info.cut_off_days_deliver + " days before fulfillment.";
                    } else if (parseInt(info.cut_off_days_deliver,10) > 0) {
                        cutOffText += " for next day delivery.";
                    } else {
                        cutOffText += " daily.";
                    }
                    if (parseFloat(info.discount_percent_deliver) > 0) {
                        discountText = " Delivery Standing Orders receive a " + info.discount_percent_deliver + "% discount off the list price.";
                    }
                    // todo: Min order amount from selected postcode
                }

                if (cutOffText !== null || discountText !== null) {
                    return <mobiscroll.Note color="info">
                        {cutOffText}
                        {discountText}
                    </mobiscroll.Note>
                }
            }
        }
        return null;
    }

    isValidFulfilmentTypeSelected = () => {
        const validFulfilmentTypes = [mpoFulfilment.fulfilmentTypePickup, mpoFulfilment.fulfilmentTypeDinein, mpoFulfilment.fulfilmentTypeDelivery];
        return this.isValidStoreSelected() &&
                this.state.merchant !== null && parseInt(this.state.merchant.id, 10) === parseInt(this.state.standing_order.store_id, 10) &&
                this.state.merchant.hasOwnProperty('fulfilment') !== null && this.state.merchant.fulfilment.length > 0 &&
                this.state.standing_order.hasOwnProperty('fulfilment_type') && this.state.standing_order.fulfilment_type !== null &&
                validFulfilmentTypes.includes(this.state.standing_order.fulfilment_type);
    }

    isAllValidFulfilmentOptionSelected = () => {
        if (this.isValidFulfilmentTypeSelected()) {
            if (this.isSelectedFulfilmentTypePickup()) {
                return true;
            //} else if (this.isSelectedFulfilmentTypeDinein()) {
            } else if (this.isSelectedFulfilmentTypeDelivery()) {
                const selectedFulfilmentType = this.getSelectedFulfilmentType();
                const selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(this.state.standing_order.fulfilment_type);
                const isDeliveryAddressRequired = mpoFulfilment.isDeliveryAddressRequired(selectedFulfilmentType, selectedFulfilmentOptions);
                const isDeliverySlotsRequired = mpoFulfilment.isDeliverySlotRequired(selectedFulfilmentType, selectedFulfilmentOptions); //&& this.isValidDeliveryAddress();
                const isDeliveryStopsRequired = mpoFulfilment.isDeliveryStopRequired(selectedFulfilmentType, selectedFulfilmentOptions);

                return (!isDeliveryAddressRequired || this.isValidDeliveryAddress()) &&
                    (!isDeliverySlotsRequired || this.isValidDeliverySlot()) &&
                    (!isDeliveryStopsRequired || this.isValidDeliveryStop());
            }
        }
        return false;
    }

    getFulfilmentTypeJsx = () => {
        let fulfilmentJsx = null;
        if (this.isValidStoreSelected() &&
            this.state.merchant !== null && parseInt(this.state.merchant.id, 10) === parseInt(this.state.standing_order.store_id, 10) &&
            this.state.merchant.hasOwnProperty('fulfilment') && this.state.merchant.fulfilment !== null && this.state.merchant.fulfilment.length > 1) {
            const storeSettings = this.getStoreStandingOrderSettings();
            fulfilmentJsx = <label>
                Fulfilment
                <mobiscroll.Select
                    select="single"
                    value={this.state.standing_order.fulfilment_type}
                    placeholder="Choose store"
                    onSet={this.setFulfilmentType}
                    disabled={this.state.mode !== 'new'}
                >
                    <option key={0} value={0}>Select Fulfilment</option>
                    {this.state.merchant.fulfilment.filter(
                            (fulfilment, idx) => (fulfilment.code === mpoFulfilment.fulfilmentTypeCodePickup && storeSettings.available_pickup) ||
                            (fulfilment.code === mpoFulfilment.fulfilmentTypeCodeDinein && storeSettings.available_dinein) ||
                            (fulfilment.code === mpoFulfilment.fulfilmentTypeCodeDelivery && storeSettings.available_deliver)
                        ).map((fulfilment, idx) => {
                            let selectedFulfilmentTypeCode = mpoFulfilment.fulfilmentTypePickup;
                            for (let code in fulfilment.fulfilment_types) {
                                selectedFulfilmentTypeCode = code;
                                break;
                            }
                            return <option key={fulfilment.id} value={fulfilment.fulfilment_types[selectedFulfilmentTypeCode].code}>{fulfilment.verb}</option>
                        })}
                </mobiscroll.Select>
            </label>
        }
        return fulfilmentJsx;
    }

    setFulfilmentType = (event, inst) => {
        // console.log(event, inst);
        // console.log(inst.getVal());
        this.setState({
            standing_order: updateObject(this.state.standing_order, {
                fulfilment_type: inst.getVal()
            })
        });
    }

    isValidDeliveryAddress = () => {
        return (this.state.standing_order.delivery_address_id > 0 || this.state.delivery.delivery_postcode !== "") && this.state.delivery.delivery_validated && this.state.delivery.delivery_postcode_id > 0;
    }

    isValidDeliverySlot = () => {
        return this.state.standing_order.delivery_slot_id > 0;
    }

    isValidDeliveryStop = () => {
        return this.state.standing_order.delivery_stop_id;
    }

    getFulfilmentOptionsJsx = () => {
        if (this.isValidFulfilmentTypeSelected()) {
            const info = this.getStoreStandingOrderSettings();
            if (this.isSelectedFulfilmentTypePickup()) {
                return <mobiscroll.Time
                    headerText={"Pickup from "+info.available_pickup_from}
                    min={info.available_pickup_from}
                    max={info.available_pickup_to}
                    value={this.state.standing_order.fulfilment_time !== null ? this.state.standing_order.fulfilment_time : info.available_pickup_from}
                    steps={{minute: 5, zeroBased: true}}
                    timeFormat="HH:ii"
                    onSet={this.fulfilmentTimeSet}>
                    <mobiscroll.Input>Pickup Time</mobiscroll.Input>
                </mobiscroll.Time>
            } else if (this.isSelectedFulfilmentTypeDinein()) {
            } else if (this.isSelectedFulfilmentTypeDelivery()) {
                const selectedFulfilmentType = this.getSelectedFulfilmentType();
                const selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(this.state.standing_order.fulfilment_type);
                //console.log(selectedFulfilmentType);
                //console.log(selectedFulfilmentOptions);
                const isDeliveryAddressRequired = mpoFulfilment.isDeliveryAddressRequired(selectedFulfilmentType, selectedFulfilmentOptions);
                //console.log(isDeliveryAddressRequired);
                const isDeliverySlotsRequired = mpoFulfilment.isDeliverySlotRequired(selectedFulfilmentType, selectedFulfilmentOptions); //&& this.isValidDeliveryAddress();
                //console.log(isDeliverySlotsRequired);
                const isDeliveryStopsRequired = mpoFulfilment.isDeliveryStopRequired(selectedFulfilmentType, selectedFulfilmentOptions);
                //console.log(isDeliveryStopsRequired);

                const deliveryAddressJsx = isDeliveryAddressRequired ?
                    <DeliveryAddress
                        merchant={this.state.merchant}
                        delivery={this.state.delivery}
                        delivery_address_id={this.state.standing_order.delivery_address_id}
                        showHeader={false}
                        showCustomerInfo={false}
                        handleChangeDeliveryAddress={this.handleChangeDeliveryAddress}
                        showValidateButton={true} />
                    : null;

                // this.state.delivery.hasOwnProperty('delivery_slots')
                const deliverySlotsJsx = isDeliverySlotsRequired && (!isDeliveryAddressRequired || this.isValidDeliveryAddress()) ?
                    <DeliverySlot delivery={this.state.delivery}
                                  delivery_slot_id={this.state.standing_order.delivery_slot_id}
                                  handleChangeDeliverySlot={this.handleChangeDeliverySlot} />
                    : null;

                const deliveryStopsJsx = isDeliveryStopsRequired ?
                    <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>(Standing Orders for Delivery Stops not yet available)</div>
                    : null;

                return <React.Fragment>
                    {deliveryAddressJsx}
                    {deliverySlotsJsx}
                    {deliveryStopsJsx}
                </React.Fragment>
            }
        }
        return null;
    }

    fulfilmentTimeSet = (e, inst) => {
        //console.log(e,inst);
        //console.log(inst.getVal().getTime());
        //console.log(inst.getDate());
        this.setState({
            standing_order: updateObject(this.state.standing_order, {
                fulfilment_time: e.valueText
            })
        });
    }

    handleChangeProductQty = (_State) => {
        //console.log("StandingOrder.handleChangeProductQty");
        //console.log(_State);
        for (var i = 0; i < this.state.standing_order.items.length; i++) {
            if (this.state.standing_order.items[i].menu_item_id === _State.item.menu_item_id &&
                this.state.standing_order.items[i].category_id === _State.item.category_id) {
                //console.log(this.state.standing_order.items);
                let updatedState = updateObject(this.state.standing_order, {});
                updatedState.items[i] = _State.item;
                //console.log(updatedState);
                this.setState({
                    standing_order: updatedState
                });
                break;
            }
        }
    }

    handleChangeDeliveryAddress = (_State) => {
        //console.log("StandingOrder.handleChangeDeliveryAddress");
        //console.log(_State);

        const standingOrderState = updateObject(this.state.standing_order, {
            delivery_address_id: _State.delivery.delivery_address_id
        });
        const updatedState = updateObject(this.state, {
            delivery: _State.delivery,
            standing_order: standingOrderState
        });
        this.setState(updatedState);
    }

    handleChangeDeliverySlot = (_State) => {
        //console.log("StandingOrder.handleChangeDeliverySlot");
        //console.log(_State);

        const standingOrderState = updateObject(this.state.standing_order, {
            delivery_slot_id: _State.delivery.delivery_slot_id
        });
        const updatedState = updateObject(this.state, {
            standing_order: standingOrderState
        });
        this.setState(updatedState);
    }

    loadModifiersPostDataHandler = (menuItemId, selectedOptions) => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Loading product...', duration: 3000, display: 'center', color: 'info'});
        const data = {
            RequestAction: 'MenuItemDetails',
            menu_item_id: menuItemId,
            cart_item_slot_id: -1
        };
        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                //mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {
                    this.showModifiersPopup(response.data.Response, selectedOptions);
                } else {
                    mobiscroll.notification.dismiss();
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }
            })
            .catch(error => {
                //logger(error);
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: 'Error, please try again (2)', color: 'danger'});
                mpoSentry.captureException(error);
            });
    }

    showModifiersPopup = (data, selectedOptions) => {
        mpoSentry.addBreadcrumb('nav','showModifiersPopup','info');

        let modifiers = data.options;

        if (modifiers.length > 0) {

            if (this.modifiersModal.current !== null) {
                this.modifiersModal.current.setState({
                    isLoading: false,
                    store_id: this.isValidStoreSelected() ? this.state.standing_order.store_id : 0,
                    menu_id: 0,
                    menu_item_id: parseInt(data.item_id, 10),
                    name: data.name,
                    desc: data.desc,
                    qty: 1,
                    min_qty: parseInt(data.min_qty, 10),
                    max_qty: data.max_qty && parseInt(data.max_qty, 10) > 0 ? data.max_qty : 999,
                    qty_label: data.qty_label,
                    availability_notice: data.availability_notice,
                    base_price: parseFloat(data.base_price),
                    thumb_url: data.thumb_url,
                    image_url: data.image_url,
                    cart_item_slot_id: parseInt(data.cart_item_slot_id, 10),
                    modifiers: modifiers,
                    selectedOptions: selectedOptions,
                    addToCartLabel: "Save",
                    addToCartIcon: "",
                    goToCart: false,
                    displayQty: false,
                    validateOnly: true
                });

                this.modifiersModal.current.refs.popupModifiers.instance.show();
            } else {
                // component unmounted?
                mobiscroll.toast({message: "Error displaying modifiers, try again", color: 'danger'});
                mpoSentry.captureException("this.modifiersModal.current === null (standing orders)");
            }

        } else {

            mobiscroll.toast({message: 'Product has no options', duration: 1500, display: 'center', color: 'warning'});

        }
    }

    updateStateWithModifiers = (data) => {
        //console.log(data);

        for (var i = 0; i < this.state.standing_order.items.length; i++) {
            if (parseInt(this.state.standing_order.items[i].menu_item_id,10) === parseInt(data.menu_item_id,10)) {
                //console.log(this.state.standing_order.items);
                let updatedState = updateObject(this.state.standing_order, {});
                updatedState.items[i] = this.state.standing_order.items[i];
                updatedState.items[i].options = data.options;
                //console.log(updatedState);
                this.setState({
                    standing_order: updatedState
                });
                break;
            }
        }

    }

    getMenuAvailability = () => {
        //console.log('getMenuAvailability');
        let availability = null;
        if (this.isValidFulfilmentTypeSelected()) {
            const selectedFulfilmentType = this.getSelectedFulfilmentType();
            const selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(this.state.standing_order.fulfilment_type);
            const isDeliverySlotsRequired = mpoFulfilment.isDeliverySlotRequired(selectedFulfilmentType, selectedFulfilmentOptions);
            const isDeliveryStopsRequired = mpoFulfilment.isDeliveryStopRequired(selectedFulfilmentType, selectedFulfilmentOptions);

            if (isDeliverySlotsRequired) {
                if (this.state.delivery.hasOwnProperty('delivery_slots')) {
                    if (this.state.delivery.hasOwnProperty('delivery_slots') &&  this.state.delivery.delivery_slots.length > 0 &&
                        this.state.standing_order.hasOwnProperty('delivery_slot_id') && this.state.standing_order.delivery_slot_id > 0) {
                        for (var i = 0; i < this.state.delivery.delivery_slots.length; i++) {
                            if (this.state.delivery.delivery_slots[i].delivery_slot_id === this.state.standing_order.delivery_slot_id) {
                                availability = this.state.delivery.delivery_slots[i];
                                break;
                            }
                        }
                    }
                }
            } else if (isDeliveryStopsRequired) {
                if (this.state.delivery.hasOwnProperty('delivery_stops') &&  this.state.delivery.delivery_stops.length > 0 &&
                    this.state.standing_order.hasOwnProperty('delivery_stop_id') && this.state.standing_order.delivery_stop_id > 0) {
                    for (var i = 0; i < this.state.delivery.delivery_stops.length; i++) {
                        if (this.state.delivery.delivery_stops[i].delivery_stop_id === this.state.standing_order.delivery_stop_id) {
                            availability = this.state.delivery.delivery_stops[i];
                            break;
                        }
                    }
                }
            } else {
                availability = selectedFulfilmentType.availability;
            }
        }
        return availability;
    }

    setCategoryId = (event, inst) => {
        // console.log(event, inst);
        // console.log(inst.getVal());
        this.setState(updateObject(this.state, {
            category_id: parseInt(inst.getVal(),10)
        }));
    }

    getCategoriesJsx = () => {
        let categoriesJsx = null;
        if (this.isAllValidFulfilmentOptionSelected()) {

            let categories = [];
            let categoryIds = [];
            for (var i = 0; i < this.state.standing_order.items.length; i++) {
                if (categoryIds.indexOf(this.state.standing_order.items[i].category_id) < 0) {
                    categoryIds.push(this.state.standing_order.items[i].category_id);
                    categories.push({
                        id: this.state.standing_order.items[i].category_id,
                        desc: this.state.standing_order.items[i].category_desc
                    });
                }
            }
            //console.log(categories);

            categoriesJsx = <label>
                Products
                <mobiscroll.Select
                    select="single"
                    value={this.state.category_id}
                    placeholder="Choose category"
                    onSet={this.setCategoryId}
                >
                    <option key={0} value={0}>All Products</option>
                    {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.desc}</option>)}
                </mobiscroll.Select>
            </label>
        }
        return categoriesJsx;
    }

    getProductsGridJsx = () => {
        if (this.isAllValidFulfilmentOptionSelected()) {
            const menuAvailability = this.getMenuAvailability();
            //console.log(menuAvailability);
            const isPickup = this.isSelectedFulfilmentTypePickup();
            const isDinein = this.isSelectedFulfilmentTypeDinein();
            const isDelivery = this.isSelectedFulfilmentTypeDelivery();

            if (menuAvailability) {
                return <div className="mbsc-grid">
                    {this.state.standing_order.items
                        .filter(item => (
                            (isPickup && parseInt(item.available_pickup, 10) === 1) || (isDinein && parseInt(item.available_dinein, 10) === 1) || (isDelivery && parseInt(item.available_deliver, 10) === 1)) &&
                            (this.state.category_id === null || this.state.category_id === 0 || this.state.category_id === parseInt(item.category_id,10))
                        )
                        .map(item => <StandingOrderProductItem key={item.category_id + "_" + item.menu_item_id}
                                                               locale={this.state.merchant.locale} item={item}
                                                               menuAvailability={menuAvailability}
                                                               showUnavailableDays={false}
                                                               handleChangeProductQty={this.handleChangeProductQty}
                                                               handleLoadModifiers={this.loadModifiersPostDataHandler} />
                                                               )}
                </div>
            } else {
                return <mobiscroll.Note color="danger" className="mbsc-align-center">Products not available</mobiscroll.Note>
            }
        }
        return null;
    }

    customerHasPaymentMethod = () => {
        return this.state.merchant !== null && this.props.user.customer.has_saved_card && this.props.user.customer.payment_gateway_id === this.state.merchant.payment_gateway_id
    }

    getAddCardJsx = (isPaymentMethodRequired) => {
        return <React.Fragment>
            <mobiscroll.Note color="info">You do not have a saved credit/debit card.
                {isPaymentMethodRequired ?
                    <span> Add a new card to save it to your account before setting up your standing order.</span>
                    :
                    <span> Adding a new card to your account is recommended to ensure that your standing orders can be executed successfully.</span>
                }
            </mobiscroll.Note>
            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                <mobiscroll.Button block={true} onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/account/addcard')
                }}>Add Card
                </mobiscroll.Button>
            </div>
        </React.Fragment>
    }

    getPaymentMethodJsx = (isPaymentMethodRequired) => {
        let paymentMethodJsx = null;
        if (this.isAllValidFulfilmentOptionSelected()) {
            if (this.customerHasPaymentMethod()) {
                //todo: tidy up and add option to change
                return <mobiscroll.Note color="secondary"
                                        className="mbsc-align-center">Payment: {this.props.user.customer.credit_card_type} ending {this.props.user.customer.credit_card_ending}</mobiscroll.Note>
            } else {
                return this.getAddCardJsx(isPaymentMethodRequired);
            }
        }
        return paymentMethodJsx;
    }

    isStandingOrderActive = () => {
        return this.isValidFulfilmentTypeSelected() && this.state.standing_order.hasOwnProperty('status') && parseInt(this.state.standing_order.status,10) === 1;
    }

    getActionButtonsTopJsx = () => {
        let switchJsx = null;
        if (this.isValidStoreSelected() && this.state.mode === 'edit') {
            const isStandingOrderActive = this.isStandingOrderActive();
            const switchLabel = "Standing Order " + (isStandingOrderActive ? "Active" : "Paused");
            let switchDesc = null;

            if (isStandingOrderActive) {
                const info = this.getStoreStandingOrderSettings();
                if (this.isSelectedFulfilmentTypePickup()) {
                    switchDesc = "Pickup Standing Orders are processed daily at  " + info.cut_off_time_pickup_12h;
                } else if (this.isSelectedFulfilmentTypeDinein()) {
                    switchDesc = "Dine-in Standing Orders are processed daily at  " + info.cut_off_time_dinein_12h;
                } else if (this.isSelectedFulfilmentTypeDelivery()) {
                    switchDesc = "Delivery Standing Orders are processed daily at  " + info.cut_off_time_deliver_12h;
                }
            } else {
                switchDesc = "Reactivate your Standing Order any time";
            }

            switchJsx = <mobiscroll.Switch checked={this.isStandingOrderActive()} onChange={this.changeSwitchStatus}>
                {switchLabel}
                <span className="mbsc-desc">{switchDesc}</span>
            </mobiscroll.Switch>
        }
        return switchJsx;
    }

    changeSwitchStatus = (ev) => {
        this.setState({
            standing_order: updateObject(this.state.standing_order, {
                status: ev.target.checked ? 1 : 0
            })
        }, this.saveStandingOrderStatus);
    }

    getActionButtonsBottomJsx = () => {
        if (this.isAllValidFulfilmentOptionSelected()) {
            return <div className="mbsc-btn-group-block">
                <mobiscroll.Button color="success" style={{color: '#fff'}}
                                   disabled={!this.isValidFulfilmentTypeSelected()} onClick={(e) => {
                    e.preventDefault();
                    this.saveStandingOrder();
                }}>Save
                </mobiscroll.Button>
            </div>
        }
        return null;
    }

    render = () => {

        let heading = "loading...";
        //if (!this.state.isLoading) {
            if (this.state.mode === 'edit') {
                heading = "Edit Standing Order";
            } else {
                heading = "New Standing Order";
            }
        //}

        const storeSettings = this.getStoreStandingOrderSettings();
        const isPaymentMethodRequired = storeSettings !== null ? parseInt(storeSettings.payment_method_required, 10) === 1 : true;

        const infoJsx = this.getInfoJsx();
        const storesJsx = this.getStoresJsx();
        const fulfilmentTypeJsx = this.getFulfilmentTypeJsx();
        const fulfilmentOptionsJsx = this.getFulfilmentOptionsJsx();
        const categoriesJsx = this.getCategoriesJsx();
        const productsGridJsx = this.getProductsGridJsx();
        const addCardJsx = this.getAddCardJsx(isPaymentMethodRequired);
        const paymentMethodJsx = this.getPaymentMethodJsx(isPaymentMethodRequired);
        const actionButtonsTopJsx = this.getActionButtonsTopJsx();
        const actionButtonsBottomJsx = this.getActionButtonsBottomJsx();

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Standing Orders`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-form-group-title">{heading}</div>

                    {this.state.isLoading ?
                        <React.Fragment><div className="mbsc-padding">Loading...</div></React.Fragment>
                        :
                        <React.Fragment>
                            {!isPaymentMethodRequired || this.customerHasPaymentMethod() ?
                                <React.Fragment>
                                    {actionButtonsTopJsx}
                                    {storesJsx}
                                    {fulfilmentTypeJsx}
                                    {fulfilmentOptionsJsx}
                                    {infoJsx}
                                    {paymentMethodJsx}
                                    {categoriesJsx}
                                    {productsGridJsx}
                                    {actionButtonsBottomJsx}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {addCardJsx}
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </mobiscroll.Form>

                <Modifiers ref={this.modifiersModal} updateStateWithOrder={this.updateStateWithModifiers} locale={this.getMerchantLocale} />

                {/*
                <AccountBottomNav />
                */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps, standingOrderId = 0) => {
            let redirect = '/account/standingorder';
            if (standingOrderId > 0) {
                redirect += '/'+standingOrderId;
            }
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountStandingOrder));
